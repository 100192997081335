<template>
  <v-select
    v-bind:items="yardOptions"
    v-bind:rules="defaultYardRules"
    item-text="name"
    v-model="defaultYard"
    label="Default Yard"
    return-object
    dense
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import rules from '../constants/rules';

export default {
  data: () => ({
    defaultYardRules: [
      rules.rules.objectRequired,
    ],
  }),
  computed: {
    ...mapGetters('admin', ['user']),
    ...mapGetters('yard', ['yards']),
    defaultYard: {
      get() {
        return this.user.defaultYard;
      },
      set(value) {
        this.setUserDefaultYard(value);
      },
    },
    yardOptions() {
      if (this.user.accessibleYards.length > 0) {
        return this.user.accessibleYards;
      }
      return this.yards;
    },
  },
  methods: {
    ...mapActions('admin', ['setUserDefaultYard']),
    ...mapActions('yard', ['getYards']),
  },
  mounted() {
    this.getYards();
  },
};
</script>
