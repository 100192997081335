<template>
  <v-card height="89vh">
    <v-card-title>
      Manage Users &nbsp;&nbsp;
      <v-btn color="primary" @click=addClicked()>
        Add User
      </v-btn>
      <v-spacer> </v-spacer>
      <v-text-field
        v-model="searchTerm"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-dialog v-if="showUserEntry" v-model="showUserEntry" persistent max-width="800">
      <user-entry></user-entry>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="filteredUsers"
      v-bind:disable-pagination="true"
      v-bind:hide-default-footer="true"
      v-bind:fixed-header="true"
      :loading="isLoading"
      :expanded="filteredUsers"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      loading-text="Loading... Please wait"
      height="79vh"
    >
      <template v-slot:item.yards="{ item }">
        <td>{{ getYards(item.accessibleYards) }}</td>
      </template>
      <template v-slot:item.roles="{ item }">
        <td>{{ getRoles(item.roles) }}</td>
      </template>
      <template
        v-slot:expanded-item="{ headers, item }"
        color="primary"
      >
        <td
          id="expanded-area"
          :colspan="headers.length"
          bgcolor="white"
        >
          <v-btn
            class="mr-4 my-4"
            color="neutral"
            min-width="8vw"
            @click="editClicked(item)"
          >
            Edit
          </v-btn>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import UserEntry from './userEntry.vue';

export default {
  name: 'manageUsers',
  components: { UserEntry },
  data: () => ({
    loadTable: true,
    headers: [
      { text: 'Name', value: 'fullName' },
      { text: 'Email', value: 'email' },
      { text: 'Accessible Yards', value: 'yards', sortable: false },
      { text: 'Roles', value: 'roles', sortable: false },
      { text: 'Status', value: 'status' },
    ],
    sortBy: 'fullName',
    sortDesc: false,
  }),
  computed: {
    ...mapGetters('admin', ['users', 'filteredUsers', 'isLoading', 'showUserEntry']),
    ...mapGetters('account', ['fullName']),
    searchTerm: {
      get() {
        return this.filter;
      },
      set(value) {
        this.setUserFilter(value);
      },
    },
  },
  methods: {
    ...mapActions('admin', ['getUsers', 'setUser', 'setUserFilter', 'setShowUserEntry']),
    getYards(yards) {
      const yardNames = _.map(yards, 'name');
      return yardNames.join(', ');
    },
    getRoles(roles) {
      return roles.join(', ');
    },
    addClicked() {
      this.setShowUserEntry(true);
    },
    editClicked(user) {
      const clonedUser = _.cloneDeep(user);
      this.setUser(clonedUser);
      this.setShowUserEntry(true);
    },
  },
  created() {
    this.getUsers();
  },
};
</script>

<style>
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    margin-top: 8px;
    padding-top: 8px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  #expanded-area {
    border-bottom: thin solid rgba(0, 0, 0, 0.15);
  }

  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) >
  td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: thin solid rgba(0, 0, 0, 0);
}
</style>
