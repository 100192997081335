<template>
  <v-card>
    <v-card-title>
      <v-container>
        <v-row>
          <v-col>
            <span v-if="user.id">Update User Information Below:</span>
            <span v-if="!user.id">Add User Information Below:</span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-form
        ref="userEntryForm"
        v-model="validForm"
      >
        <v-container fluid>
          <v-row>
            <v-col>
              <UserNameEntry
                type="firstName" />
            </v-col>
            <v-col>
              <UserNameEntry
                type="lastName" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <UserEmailEntry />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <UserPasswordEntry />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <UserAccessibleYardsEntry />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <UserDefaultYardEntry />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <UserRolesEntry />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <UserStatusEntry />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-container>
        <v-row>
          <v-col>
            <v-btn class="mr-4 my-4" min-width="7vw" color="primary" outlined @click="saveClicked" v-if="!user.id"> Save </v-btn>
            <v-btn class="mr-4 my-4" min-width="7vw" color="primary" outlined @click="updateClicked" v-if="user.id" :disabled="!validForm"> Update </v-btn>
            <v-btn min-width="7vw" color="secondary" outlined @click="closeClicked"> Close </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import UserNameEntry from './userNameEntry.vue';
import UserEmailEntry from './userEmailEntry.vue';
import UserPasswordEntry from './userPasswordEntry.vue';
import UserAccessibleYardsEntry from './userAccessibleYardsEntry.vue';
import UserDefaultYardEntry from './userDefaultYardEntry.vue';
import UserRolesEntry from './userRolesEntry.vue';
import UserStatusEntry from './userStatusEntry.vue';
import userMapper from '../mappers/userMapper';

export default {
  components: {
    UserNameEntry,
    UserEmailEntry,
    UserPasswordEntry,
    UserAccessibleYardsEntry,
    UserDefaultYardEntry,
    UserRolesEntry,
    UserStatusEntry,
  },
  data: () => ({
    validForm: true,
  }),
  computed: {
    ...mapGetters('admin', ['user', 'password']),
    ...mapGetters('account', ['fullName']),
  },
  methods: {
    ...mapActions('admin', ['resetState', 'createUser', 'updateUser', 'setUser', 'setShowUserEntry']),
    saveClicked() {
      const isValid = this.$refs.userEntryForm.validate();
      if (isValid) {
        const request = {
          user: this.user,
          password: this.password,
          enteredBy: this.fullName,
        };
        this.createUser(request);
        this.setUser(_.cloneDeep(userMapper.defaultUser));
        this.setShowUserEntry(false);
      }
    },
    updateClicked() {
      const isValid = this.$refs.userEntryForm.validate();
      if (isValid) {
        const request = {
          user: this.user,
          password: this.password,
          enteredBy: this.fullName,
        };
        this.updateUser(request);
        this.setUser(_.cloneDeep(userMapper.defaultUser));
        this.setShowUserEntry(false);
      }
    },
    closeClicked() {
      this.setUser(_.cloneDeep(userMapper.defaultUser));
      this.setShowUserEntry(false);
    },
  },
};
</script>
