<template>
  <v-select
    v-bind:items="grantableRoleNames"
    v-bind:rules="rolesRules"
    v-model="roles"
    label="Role(s)"
    multiple
    chips
    dense
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import rules from '../constants/rules';

export default {
  data: () => ({
    rolesRules: [
      rules.rules.arrayNotEmpty,
    ],
  }),
  computed: {
    ...mapGetters('admin', ['user']),
    ...mapGetters('config', ['roleNames']),
    ...mapGetters({ currentUser: 'account/user' }),
    roles: {
      get() {
        return this.user.roles;
      },
      set(value) {
        this.setUserRoles(value);
      },
    },
    grantableRoleNames() {
      let grantableRoles = this.roleNames;
      if (!this.currentUser.roles.includes('Developer')) {
        grantableRoles = this.currentUser.roles.filter((role) => role.name !== 'Developer');
      }
      return grantableRoles;
    },
  },
  methods: {
    ...mapActions('admin', ['setUserRoles']),
  },
  mounted() {
    this.$store.dispatch('config/getRoles');
  },
};
</script>
