<template>
  <v-select
    v-bind:items="yards"
    v-bind:rules="accessibleYardsRules"
    item-text="name"
    v-model="accessibleYards"
    label="Accessible Yard(s)"
    return-object
    multiple
    chips
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import rules from '../constants/rules';

export default {
  data: () => ({
    accessibleYardsRules: [
      rules.rules.arrayNotEmpty,
    ],
  }),
  computed: {
    ...mapGetters('admin', ['user']),
    ...mapGetters('yard', ['yards']),
    accessibleYards: {
      get() {
        return this.user.accessibleYards;
      },
      set(value) {
        this.setUserAccessibleYards(value);
      },
    },
  },
  watch: {
    accessibleYards() {
      const isDefaultYardPresent = this.accessibleYards.find((accessibleYard) => accessibleYard.yardId === this.user.defaultYard.yardId);
      if (!isDefaultYardPresent) {
        this.setUserDefaultYard({});
      }
    },
  },
  methods: {
    ...mapActions('admin', ['setUserAccessibleYards', 'setUserDefaultYard']),
    ...mapActions('yard', ['getYards']),
  },
  mounted() {
    this.getYards();
  },
};
</script>
