<template>
  <v-text-field
    v-model="email"
    v-bind:rules="emailRules"
    label="Email"
    dense
  ></v-text-field>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import rules from '../constants/rules';

export default {
  data: () => ({
    emailRules: [
      rules.rules.required,
    ],
  }),
  computed: {
    ...mapGetters('admin', ['user']),
    email: {
      get() {
        return this.user.email;
      },
      set(value) {
        this.setUserEmail(value);
      },
    },
  },
  methods: {
    ...mapActions('admin', ['setUserEmail']),
  },
};
</script>
