<template>
  <v-select
    v-bind:items="statusOptions"
    v-bind:rules="statusRules"
    v-model="status"
    label="Status"
    dense
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import rules from '../constants/rules';
import userStatuses from '../constants/userStatuses';

export default {
  data: () => ({
    statusOptions: userStatuses.ALL_STATUSES,
    statusRules: [
      rules.rules.required,
    ],
  }),
  computed: {
    ...mapGetters('admin', ['user']),
    status: {
      get() {
        return this.user.status;
      },
      set(value) {
        this.setUserStatus(value);
      },
    },
  },
  methods: {
    ...mapActions('admin', ['setUserStatus']),
  },
};
</script>
