<template>
<div>
<manage-users></manage-users>
</div>
</template>

<script>
import ManageUsers from '../components/manageUsers.vue';

export default {
  name: 'ManageUsersView',
  components: { ManageUsers },
};
</script>
