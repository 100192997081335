<template>
  <v-text-field
    v-model="name"
    v-bind:rules="nameRules"
    :label=label
    dense
  ></v-text-field>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import rules from '../constants/rules';

export default {
  props: ['type'],
  data: () => ({
    nameRules: [
      rules.rules.required,
    ],
  }),
  computed: {
    ...mapGetters('admin', ['user']),
    name: {
      get() {
        if (this.type === 'firstName') {
          return this.user.firstName;
        }
        return this.user.lastName;
      },
      set(value) {
        if (this.type === 'firstName') {
          this.setUserFirstName(value);
        } else {
          this.setUserLastName(value);
        }
      },
    },
    label() {
      if (this.type === 'firstName') {
        return 'First Name';
      }
      return 'Last Name';
    },
  },
  methods: {
    ...mapActions('admin', ['setUserFirstName', 'setUserLastName']),
  },
};
</script>
