<template>
  <v-text-field
    v-model="password"
    v-bind:rules="passwordRules"
    label="Password"
    dense
  ></v-text-field>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import rules from '../constants/rules';

export default {
  computed: {
    ...mapGetters('admin', ['user']),
    password: {
      get() {
        return '';
      },
      set(value) {
        this.setUserPassword(value);
      },
    },
    passwordRules() {
      if (this.user.id === '') {
        return [rules.rules.required];
      }
      return [];
    },
  },
  methods: {
    ...mapActions('admin', ['setUserPassword']),
  },
};
</script>
