var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"89vh"}},[_c('v-card-title',[_vm._v(" Manage Users    "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addClicked()}}},[_vm._v(" Add User ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),(_vm.showUserEntry)?_c('v-dialog',{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.showUserEntry),callback:function ($$v) {_vm.showUserEntry=$$v},expression:"showUserEntry"}},[_c('user-entry')],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredUsers,"disable-pagination":true,"hide-default-footer":true,"fixed-header":true,"loading":_vm.isLoading,"expanded":_vm.filteredUsers,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading-text":"Loading... Please wait","height":"79vh"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.yards",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.getYards(item.accessibleYards)))])]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.getRoles(item.roles)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"id":"expanded-area","colspan":headers.length,"bgcolor":"white"}},[_c('v-btn',{staticClass:"mr-4 my-4",attrs:{"color":"neutral","min-width":"8vw"},on:{"click":function($event){return _vm.editClicked(item)}}},[_vm._v(" Edit ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }